import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

export const PERSON_NAME_FORMAT = {
  FULL: 'FULL',
  SHORT: 'SHORT'
};

export const LOCALE_FORMAT = {
  RU: 'RU',
  EN: 'EN'
};

const NAME_MODE = {
  FULL: 'full',
  INITIALS: 'initials',
  SKIP: 'skip'
};

const NAME_VARIANT = {
  FIRST: 'first_name',
  LAST: 'last_name',
  MIDDLE: 'middle_name'
};

const FORMAT_NAME = {
  RU: {
    FULL: [
      { name: NAME_VARIANT.LAST, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.FIRST, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.MIDDLE, mode: NAME_MODE.FULL },
    ],
    SHORT: [
      { name: NAME_VARIANT.LAST, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.FIRST, mode: NAME_MODE.INITIALS },
      { name: NAME_VARIANT.MIDDLE, mode: NAME_MODE.INITIALS },
    ],
  },
  EN: {
    FULL: [
      { name: NAME_VARIANT.FIRST, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.MIDDLE, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.LAST, mode: NAME_MODE.FULL },
    ],
    SHORT: [
      { name: NAME_VARIANT.FIRST, mode: NAME_MODE.FULL },
      { name: NAME_VARIANT.MIDDLE, mode: NAME_MODE.SKIP },
      { name: NAME_VARIANT.LAST, mode: NAME_MODE.FULL },
    ],
  },
};

const capitalize = (name = '') => {
  return name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

const PersonName = (props = {}) => {
  const { format = PERSON_NAME_FORMAT.FULL, locale = LOCALE_FORMAT.RU, ...names} = props;
  const order = FORMAT_NAME[locale][format];

  let personName = [];

  for (let {name, mode} of Object.values(order)) {
    if (names[name]) {
      if (mode === NAME_MODE.FULL) {
        personName.push(names[name].trim());
      }
      if (mode === NAME_MODE.INITIALS) {
        personName.push(`${names[name].trim()[0]}.`);
      }
    }
  }

  return capitalize(personName.join(' '));
};

PersonName.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  middle_name: PropTypes.string,
  format: PropTypes.oneOf(Object.values(PERSON_NAME_FORMAT)),
  locale: PropTypes.oneOf(Object.values(LOCALE_FORMAT))
};

export default PersonName;