import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import NavBar from '../../components/NavBar';
import { RepositoryContext } from '../../store/RepositoryProvider';
import DateFormat from '../../components/DateFormat';
import API from '../../server/api';
import Table, { ALIGN } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';

const Boarding = () => {
  const {t} = useTranslation(['common', 'boarding']);

  const repository = useContext(RepositoryContext);

  const [filteredPassengers, setFilteredPassengers] = useState([]);
  const [orders, setOrders] = useState({});
  const [date, setDate] = useState(null);

  const loadPassengers = useCallback(async () => {
    let passengers = await repository.list(API.cashier.passengers, {
      date: DateFormat.Request({ date })
    });

    // filteredPassengers:
    // [
    //   {
    //     time: string,
    //     excursions: [
    //       {
    //         id: string,
    //         title: string,
    //         places: [
    //           {
    //             id: string,
    //             title: string,
    //             address: string,
    //             transports: [
    //               {
    //                 id: string,
    //                 title: string,
    //                 number: string,
    //                 passengers: [
    //                   {
    //                     phone: string,
    //                     name: string,
    //                     order: string,
    //                     seats: {
    //                       count: number,
    //                       noSeats: number,
    //                       seats: [number]
    //                     },
    //                     checked: boolean
    //                   }
    //                 ]
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]

    // orders:
    // {
    //   [id]: {
    //     transports: [
    //       {
    //         id: string,
    //         title: string,
    //         number: string
    //         seats: number
    //       }
    //     ]
    //   }
    // }

    const _orders = {};
    let boarding = [];
    for (let record of passengers) {
      let timeBoard = boarding.find((t) => t.time === record.time);
      if (!timeBoard) {
        let rg = record.time.match(/(\d{1,2}):(\d{1,2})/);
        timeBoard = {
          time: record.time,
          date: record.date,
          minutes: Number(rg[1]) * 60 + Number(rg[2]),
          excursions: []
        };
        boarding.push(timeBoard);
      }

      let excursion = timeBoard.excursions.find((e) => e.id === record.excursion.id);
      if (!excursion) {
        excursion = {
          id: record.excursion.id,
          title: record.excursion.title,
          places: []
        };
        timeBoard.excursions.push(excursion);
      }

      let place = excursion.places.find((p) => p.id === record.place.id);
      if (!place) {
        place = {
          id: record.place.id,
          title: record.place.title,
          address: record.place.address,
          transports: []
        };
        excursion.places.push(place);
      }

      for (let seat of record.seats) {
        const transportId = seat.transport ? seat.transport.id : 'none';
        let transport = place.transports.find((t) => t.id === transportId);
        if (!transport) {
          transport = {
            id: transportId,
            title: seat.transport ? seat.transport.title : null,
            number: seat.transport ? seat.transport.number : null,
            passengers: []
          };
          place.transports.push(transport);
        }

        let passenger = transport.passengers.find((p) => p.order === record.user.order);
        if (!passenger) {
          passenger = {
            phone: record.user.phone,
            name: record.user.name,
            order: record.order,
            seat: {
              seats: seat.seats,
              count: record.passengers.seats,
              noSeats: record.passengers.noSeats
            },
            checked: record.checked
          };
          transport.passengers.push(passenger);
        }

        _orders[record.order] = _orders[record.order] || {
          transports: []
        };
        _orders[record.order].transports.push({
          id: seat.transport ? seat.transport.id : 'none',
          title: seat.transport ? seat.transport.title : null,
          number: seat.transport ? seat.transport.number : null,
          seats: seat.seats ? seat.seats.length : 0
        });

      }
    }

    boarding.sort((a, b) => a.minutes - b.minutes);

    setOrders(_orders);
    setFilteredPassengers(boarding);
  }, [date]);


  const seatPassengers = useCallback(async (seat, order, place) => {
    await repository.action(API.cashier.setPassengers, {
      seat: !!seat,
      order: order,
      place: place
    });
  }, [filteredPassengers]);

  useEffect(() => {
    loadPassengers();
  }, [date]);

  useEffect(() => {
    let now = new Date();
    setDate(now);
  }, []);


  return (
    <div className="trip-app">
      <NavBar title={t('boarding:boarding.title')} />
      <div className="trip-app-body">
        <div className="header-line">{t('boarding:boarding.date')}</div>
        <SelectDate mode={DAY_PICKER_MODE.SINGLE} onChange={setDate} />
        <Table cols={5}>
          {filteredPassengers.map((time, d) => (
            <Fragment key={d}>
              {time.excursions.map((excursion, e) => (
                <Fragment key={e}>
                  {excursion.places.map((place, p) => (
                    <Fragment key={p}>
                      <Table.Row>
                        <Table.Cell>{time.time}</Table.Cell>
                        <Table.Cell colspan={2}>
                          <InfoDescription className="small" description={place.title}>
                            {excursion.title}
                          </InfoDescription>
                        </Table.Cell>
                      </Table.Row>
                      {place.transports.map((transport, b) => (
                        <Fragment key={b}>
                          <Table.Row>
                            <Table.Cell/>
                            <Table.Cell colspan={4}>
                              {transport.number !== 'none' ? t('boarding:boarding.bus', { bus: transport.number }) : t('boarding:boarding.none')}
                            </Table.Cell>
                          </Table.Row>
                          {transport.passengers.map((user, u) => (
                            <Fragment key={u}>
                              <Table.Row>
                                <Table.Cell><input type="checkbox" value={user.order} defaultChecked={user.checked} onChange={(e) => seatPassengers(e.target.checked, user.order, place.id)}/></Table.Cell>
                                <Table.Cell colspan={2}>
                                  <InfoDescription className="small" description={user.phone}>
                                    {user.name}
                                  </InfoDescription>
                                </Table.Cell>
                                <Table.Cell colspan={2} align={ALIGN.RIGHT}>
                                  <InfoDescription className="small" description=
                                    {orders[user.order].transports.map((transport, i) => (
                                      <div key={i}>{`${orders[user.order].transports.length > 1 ? transport.number + ': ' : ''}${t('boarding:place.place', { count: transport.seats })}`}</div>
                                    ))}
                                  >
                                    {user.seat.noSeats > 0 ? t('boarding:people.noseats_people', { count: user.seat.count, noSeats: user.seat.noSeats }) : t('boarding:people.people', { count: user.seat.count })}
                                  </InfoDescription>
                                </Table.Cell>
                              </Table.Row>
                            </Fragment>
                          ))}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
              <Table.Row delim={true}></Table.Row>
            </Fragment>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default Boarding;