import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Price from '../../components/Price';
import DateFormat from '../../components/DateFormat';

const TicketInput = ({ ticket }) => {
  const {t} = useTranslation(['common', 'route']);

  return (
    <div>
      <div className="info-field">
        {t('route:ticket_input.total_amount')}
        <div className="info-field-data">
          <Price currency="rub" value={ticket.amount} />
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.excursion')}
        <div className="info-field-data">
          {ticket.excursion.title}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.date_departure')}
        <div className="info-field-data">
          <DateFormat.DateTime date={ticket.date} />
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.client')}
        <div className="info-field-data">
          {ticket.name}
        </div>
        <div className="info-field-data">
          {ticket.phone}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.seller')}
        <div className="info-field-data">
          {ticket.seller.name}
        </div>
        <div className="info-field-data">
          {ticket.seller.phone}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.seats')}
        <div className="info-field-data">
          {ticket.seats.toString()}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.transport')}
        <div className="info-field-data">
          {ticket.transport.number}
        </div>
      </div>
    </div>
  );
};

TicketInput.propTypes = {
  ticket: PropTypes.any.isRequired
};

export default TicketInput;