import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: !!props.checked };
  }

  toggleChange = () => {
    const isChecked = !this.state.isChecked;
    this.setState(() => ({ isChecked }));
    typeof this.props.onChange === 'function' && this.props.onChange(this.props.value, isChecked);
  }

  static getDerivedStateFromProps(props, state) {
    return { isChecked: props.checked };
  }

  render() {
    return (
      <label className={`checkbox-item ${this.props.className || ''}`}>
        <input type="checkbox" defaultChecked={this.state.isChecked} onChange={this.toggleChange} value={this.props.value} disabled={this.props.disabled} />
        {this.props.title}
      </label>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

const Group = ({ list = [], className = '', onChange, children }) => {
  return (
    <div className="checkbox-group">
      {list.length > 0 && list.map(({title, value, checked}) => (
        <Checkbox key={value} className={className} title={title} value={value} onChange={onChange} checked={checked} />
      ))}
      {!list.length && children}
    </div>
  );
};

Group.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func
};

Checkbox.Group = Group;

const Result = ({ title, value, checked, className = '' }) => {
  return (
    <span className={`checkbox-result ${checked ? 'checked' : ''} ${className}`}>
      {checked && (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 256 256" className="icon"><rect width="256" height="256" fill="none"></rect><path d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm49.53125,85.78906-58.67187,56a8.02441,8.02441,0,0,1-11.0625,0l-29.32813-28a8.00675,8.00675,0,0,1,11.0625-11.57812l23.79687,22.72656,53.14063-50.72656a8.00675,8.00675,0,0,1,11.0625,11.57812Z"></path></svg>
      )}
    </span>
  );
};

Result.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string
};

Checkbox.Result = Result;

const Button = ({ className = '', title, value, onChange, checked = false, disabled }) => {
  const [isChecked, setIsChecked] = useState(!!checked);

  const toggleChange = () => {
    setIsChecked((prevState) => !prevState)
    typeof onChange === 'function' && onChange(value, !isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label className={`checkbox-toggle ${isChecked ? 'checked' : ''} ${className}`}>
      {title}
      <input type="checkbox" checked={isChecked} onChange={toggleChange} value={value} disabled={disabled} />
    </label>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.Button = Button;

const Slide = ({ className = '', title, value, onChange, checked = false, disabled }) => {
  const [isChecked, setIsChecked] = useState(!!checked);

  const toggleChange = () => {
    setIsChecked((prevState) => !prevState)
    typeof onChange === 'function' && onChange(value, !isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={`slider-checkbox ${className}`}>
      <input type="checkbox" checked={isChecked} onChange={toggleChange} value={value} disabled={disabled} />
      <label className={`slider-checkbox-label ${isChecked ? 'checked' : ''}`}>{title}</label>
    </div>
  );
};

Slide.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.Slide = Slide;

export default Checkbox;