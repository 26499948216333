import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import OneTimeCode from '../../components/OneTimeCode';

const PHASES = {
  PHONE: 0,
  SMS_CODE: 1,
  NEW_PASSWORD: 2
}

const SMS_CODE_LENGTH = process.env.REACT_APP_SMS_CODE_LENGTH ? parseInt(process.env.REACT_APP_SMS_CODE_LENGTH) : 6;

const ForgotPassword = ({ onSubmit }) => {

  const {t} = useTranslation(['common', 'auth']);

  const [phone, setPhone] = useState({ value: '', error: false });
  const [smsCode, setSmsCode] = useState({ value: '', error: false });
  const [newPassword, setNewPassword] = useState({ value: '', error: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: '', error: false });
  const [phase, setPhase] = useState(PHASES.PHONE);
  const otcRef = useRef(null);

  const handleNext = (e) => {
    e.preventDefault();

    if (phase === PHASES.PHONE) {
      setPhase(PHASES.SMS_CODE);
    } else if (phase === PHASES.SMS_CODE) {
      setPhase(PHASES.NEW_PASSWORD);
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();

    if (phase === PHASES.SMS_CODE) {
      setPhase(PHASES.PHONE);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (phase === PHASES.NEW_PASSWORD) {
      onSubmit(true);
    }
    setPhase(PHASES.PHONE);
  };

  const handleCancel = (e) => {
    e.preventDefault();

    if (phase === PHASES.NEW_PASSWORD) {
      onSubmit(false);
    }
    setPhase(PHASES.PHONE);
  };

  return (
    <>
      {(phase === PHASES.PHONE) && (
        <div className="input">
          <label className="field-label">{t('auth:form.phone')}</label>
          <input className="text" type="text" value={phone.value} name="phone" placeholder={t('auth:form.phone_placeholder')}
            onChange={(e) => {
              const val = e.target.value.trim();
              setPhone({
                value: val,
                error: !val,
              });
            }}
          />
        </div>
      )}

      {(phase === PHASES.SMS_CODE) && (
        <div>
          <label className="field-label">{t('auth:form.sms_code')}</label>
          <OneTimeCode ref={otcRef} className="text" value={smsCode.value} name="sms_code" length={SMS_CODE_LENGTH}
            onChange={(e) => {
              const val = e.target.value.trim();
              setSmsCode({
                value: val,
                error: !val,
              });
            }}
          />
        </div>
      )}

      {(phase === PHASES.NEW_PASSWORD) && (
        <>
          <div className="input">
            <label className="field-label">{t('auth:form.new_password')}</label>
            <input className="text" type="text" value={newPassword.value} name="password"
              onChange={(e) => {
                const val = e.target.value.trim();
                setNewPassword({
                  value: val,
                  error: !val,
                });
              }}
            />
          </div>
          <div className="input">
            <label className="field-label">{t('auth:form.confirm_password')}</label>
            <input className="text" type="text" value={confirmPassword.value} name="confirm_password"
              onChange={(e) => {
                const val = e.target.value.trim();
                setConfirmPassword({
                  value: val,
                  error: !val,
                });
              }}
            />
          </div>
        </>
      )}

      <div className="button-panel">
        {(phase === PHASES.SMS_CODE) && (
          <button type="button" className="btn btn-half btn-secondary" onClick={handlePrev}>{t('auth:button.prev')}</button>
        )}
        {(phase !== PHASES.NEW_PASSWORD) && (
          <button type="button" className="btn btn-half btn-primary" onClick={handleNext}>{t('auth:button.next')}</button>
        )}
        {(phase === PHASES.NEW_PASSWORD) && (
          <>
            <button type="button" className="btn btn-half btn-primary" onClick={handleSave}>{t('auth:button.save')}</button>
            <button type="button" className="btn btn-half btn-secondary" onClick={handleCancel}>{t('auth:button.cancel')}</button>
          </>
        )}
      </div>
    </>
  )
}

export default ForgotPassword;