import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import { ru } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';

const SelectDate = ({ onSelect, onCancel, onApply, start, end }) => {
  const {t} = useTranslation(['common']);

  const [range, setRange] = useState({
    from: start,
    to: end
  });

  useEffect(() => {
    setRange({
      from: start,
      to: end
    });
  }, [start, end]);

  const handleSelectRange = (range) => {
    setRange(range);
    if (range) {
      typeof onSelect === 'function' && onSelect(range);
    }
  };

  const handleApplyDate = useCallback(() => {
    typeof onApply === 'function' && onApply(range);
  }, [range]);

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel();
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <DayPicker mode="range" locale={ru} selected={range} onSelect={handleSelectRange}/>
      </div>
      <div>
        <Button.Panel>
          <Button type={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleApplyDate}>{t('common:button.save')}</Button>
          <Button type={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={handleCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </div>
    </div>
  );
}

SelectDate.propTypes = {
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export default SelectDate;