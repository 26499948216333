import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { useContext, useEffect, useState } from 'react';
import API from '../../server/api';
import NavBar from '../../components/NavBar';
import List from '../../components/List';
import RepositoryModel from '../../components/RepositoryModel';

const Schedule = () => {
  const {t} = useTranslation(['common', 'schedule']);

  const repository = useContext(RepositoryContext);

  const [schedules, setSchedules] = useState([]);

  const loadSchedules = async () => {
    let schedules = await repository.list(API.cashier.schedules);
    setSchedules(schedules);
  };

  useEffect(() => {
    loadSchedules();
  }, []);

  return (
    <div className="trip-app">
      <NavBar title={t('schedule:schedule.title')} />
      <div className="trip-app-body">
        <List className="schedules">
          {schedules.map((s) => (
            <List.Row key={s.id}>
              <List.Cell className="align-right">
                <div className="main-line">{s.getDate()}</div>
                <div className="second-line">{t('schedule:schedule.time', {from: s.getTimeFrom(), to: s.getTimeTo()})}</div>
              </List.Cell>
              <List.Cell className="align-left">
                <div className="main-line">{s.point.title}</div>
                <div className="second-line"><RepositoryModel type="Point" uid={s.point.id} render={(model) => model.address} /></div>
              </List.Cell>
            </List.Row>
          ))}
        </List>
      </div>
    </div>
  )
}

export default Schedule;