import ClassHelper from './ClassHelper';
import DynamicPrice from './DynamicPrice';
import Price from './Price';

class ExcursionPrice extends ClassHelper {
  constructor({ _id = null, id = null, price, amount, reward, minAge, maxAge, noSeat, partialValue } = {}) {
    super(...arguments);
    this.id = _id || id;
    this.price = this.ref(price, Price);
    this.amount = amount;
    this.reward = this.obj(reward, DynamicPrice);
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.noSeat = noSeat;
    this.partialValue = partialValue;
  }
}

export default ExcursionPrice;