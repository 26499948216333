import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stepper from '../../components/Stepper';
import QRCode from '../../components/QRCode';
import Field from '../../components/Field';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Price from '../../components/Price';
import { RepositoryContext } from '../../store/RepositoryProvider';
import User from '../../models/User';
import PersonName, { PERSON_NAME_FORMAT } from '../../components/PersonName';
import API from '../../server/api';
import Collection from '../../models/Collection';
import DateFormat from '../../components/DateFormat';
import log from '../../utils/logger';

export const STEPS = {
  scan: 0,
  confirm: 1,
  collection: 2
};

const REQUEST_STATE = {
  SCAN: 0,
  CONFIRM: 1,
  COMPLETE: 2
};

class Step
{
  constructor({ id, title, onSelect, onComplete, onError, validate }) {
    this.id = id;
    this.title = title;
    this.errors = [];
    this.onSelect = typeof onSelect === 'function' ? onSelect : () => {};
    this.onComplete = typeof onComplete === 'function' ? onComplete : () => {};
    this.onError = typeof onError === 'function' ? onError : () => {};
    this.validate = typeof validate === 'function' ? validate : () => {};
  }
};

const NewCollection = ({ onCancel }) => {
  const {t} = useTranslation(['common', 'collection']);

  const [data, setData] = useState({});
  const [requestState, setRequestState] = useState(REQUEST_STATE.SCAN);
  const [page, setPage] = useState(0);
  const [cashier, setCashier] = useState(0);
  const [collection, setCollection] = useState(null);

  const repository = useContext(RepositoryContext);

  const initSteps = (t) => {
    return Object.keys(STEPS).map((step) => new Step({ title: t(`collection:${step}.title`) }));
  };

  const [steps, setSteps] = useState(initSteps(t));

  const handleSelectStep = useCallback((idx) => {
    selectPage(idx);
  }, []);

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage < steps.length && newPage >= 0) {
      // steps[page].status = STEPPER_ITEM_STATUS.COMPLETED;
      // setSteps([ ...steps ]);
      setPage(newPage);
    }
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const reset = () => {
    setSteps(initSteps(t));
    setPage(0);
  };

  const selectPage = (idx) => {
    setPage(idx);
  };

  const askUser = (userId) => {
    if (userId) {
      repository.request(API.user.user, { id: userId }, User)
        .then((user) => setCashier(user))
        .catch((e) => setCashier(null))
      ;
    }
  };

  const createCollection = () => {
    if (data) {
      repository.action(API.collectors.createCollection, { user: data.cashier, amount: data.amount }, Collection)
        .then((collection) => {
          setCollection(collection);
          nextPage(); 
        })
        .catch((e) => {
          setCollection(null);
          log.error(e);
        })
      ;
    }
  };


  return (
    <div className="trip-app">
      <div className={`trip-app-header`}>
        <Stepper steps={steps} activeStep={page} onSelect={handleSelectStep}></Stepper>
      </div>
      <div className="trip-app-body">
        {page === STEPS.scan && (
          <>
            <Field title={t('collection:scan.scan')}>
              <QRCode.Scanner
                onResult={(result, error, reader) => {
                  if (result) {
                    const data = JSON.parse(result);
                    setData(data);
                    nextPage();
                    reader.stop();
                    setData({
                      cashier: data.query,
                      amount: parseFloat(data.cash)
                    });
                    askUser(data.query);
                  }

                  if (error) {
                    log.error(error);
                  }
                }}
              />

              <Button.Panel>
                <Button position={BUTTON_POSITION.RIGHT} type={BUTTON_TYPE.SECONDARY} onClick={onCancel}>{t('common:button.cancel')}</Button>
              </Button.Panel>
            </Field>
          </>
        )}
        {page === STEPS.confirm && (
          <>
            <Field title={t('collection:confirm.cashier')}>
              {cashier && (
                <PersonName first_name={cashier.profile.firstName} last_name={cashier.profile.lastName} middle_name={cashier.profile.middleName} format={PERSON_NAME_FORMAT.FULL} />
              )}
            </Field>
            <Field title={t('collection:confirm.amount')}>
              <Price currency="rub" value={data.amount} />
            </Field>

            <Button.Panel>
              <Button position={BUTTON_POSITION.LEFT} type={BUTTON_TYPE.PRIMARY} onClick={createCollection}>{t('collection:confirm.right')}</Button>
              <Button position={BUTTON_POSITION.RIGHT} type={BUTTON_TYPE.SECONDARY} onClick={onCancel}>{t('common:button.cancel')}</Button>
            </Button.Panel>
          </>
        )}
        {page === STEPS.collection && (
          <>
            <Field title={t('collection:collection.date')}>
              {collection && (
                <DateFormat value={collection.date} />
              )}
            </Field>
            <Field title={t('collection:collection.amount')}>
              {collection && (
                <Price currency="rub" value={collection.amount} />
              )}
            </Field>
          </>
        )}
      </div>
    </div>
  )
}

export default NewCollection;