import { useTranslation } from 'react-i18next';
import NavBar from '../../components/NavBar';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Table, { ALIGN } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import DateFormat from '../../components/DateFormat';
import Price from '../../components/Price';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import DatePeriod from '../../components/DatePeriod';
import Modal from '../../components/Modal';
import SelectDate from '../cashbox/select_date';

const PAYMENT_STATUS = {
  Cashier: BADGE_TYPE.SUCCESS,
  Salary: BADGE_TYPE.ERROR
};

const Salary = () => {
  const {t} = useTranslation(['common', 'salary']);

  const repository = useContext(RepositoryContext);

  const [bonuses, setBonuses] = useState([]);
  const [total, setTotal] = useState([{}]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showRangeModal, setShowRangeModal] = useState(false);

  const handleCloseRangeModal = useCallback(() => {
    setShowRangeModal(false);
  }, []);

  const handleApplyRangeModal = useCallback((range) => {
    setShowRangeModal(false);
    setStartDate(range.from);
    setEndDate(range.to || range.from);
  }, []);

  const loadBonuses = useCallback(async () => {
    let _bonuses = await repository.list(API.cashier.salaries, {
      fromDate: DateFormat.Request({ date: startDate }),
      toDate: DateFormat.Request({ date: endDate })
    });

    let _total = {
      amount: 0,
      income: 0,
      refunds: 0
    };

    _bonuses.forEach((bonus) => {
      if (bonus.amount >= 0) {
        _total.income += bonus.amount;
      } else {
        _total.refunds += bonus.amount;
      }
      _total.amount += bonus.amount;
    });

    setBonuses(_bonuses);
    setTotal(_total);
  }, [startDate, endDate]);

  useEffect(() => {
    loadBonuses();
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, []);

  return (
    <div className="trip-app">
      <NavBar title={t('salary:salary.title')} />
      <div className="trip-app-body">
        <div className="header-line">{t('salary:salary.period')}</div>
        <div className="input">
          <DatePeriod captionLayout="dropdown" className="form-control" start={startDate} end={endDate} onClick={() => setShowRangeModal(true)}/>
        </div>
        <Table cols={3}>
          <Table.Row delim={true}>
            <Table.Cell>
              <InfoDescription className="small-description" description={t('salary:total.amount')}>
                <Price currency="rub" value={total.amount} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription className="small-description" description={t('salary:total.income')}>
                <Price currency="rub" value={total.income} />
              </InfoDescription>
            </Table.Cell>
            <Table.Cell>
              <InfoDescription className="small-description" description={t('salary:total.refunds')}>
                <Price currency="rub" value={total.refunds} />
              </InfoDescription>
            </Table.Cell>
          </Table.Row>
        </Table>
        <Table cols={5}>
          {bonuses.map((salary, d) => (
            <Fragment key={d}>
              <Table.Row delim={true}>
                <Table.Cell colspan={4}>
                  <InfoDescription description={
                    <>
                      {t('salary:salary.order_number')}{salary.order.number} - {salary.excursion.title}
                    </>
                  }>
                    <DateFormat.DateTime date={salary.date} />
                  </InfoDescription>
                </Table.Cell>
                <Table.Cell align={ALIGN.RIGHT}>
                  <Badge type={PAYMENT_STATUS[salary.type]}><Price currency="rub" value={salary.amount}/></Badge>
                </Table.Cell>
              </Table.Row>
            </Fragment>
          ))}
        </Table>

        <Modal show={showRangeModal} onHide={handleCloseRangeModal}>
          <Modal.Header closeButton>
            {t('salary:date_period.title')}
          </Modal.Header>
          <Modal.Body>
            <SelectDate start={startDate} end={endDate} onApply={handleApplyRangeModal} onCancel={handleCloseRangeModal}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Salary;