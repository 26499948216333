import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Logger from '../utils/logger';
import Account from '../models/Account';
import AccountObject from '../models/AccountObject';
import { Area, City, Country, Region } from '../models/GEO';
import Category from '../models/Category';
import Excursion from '../models/Excursion';
import FreeService from '../models/FreeService';
import ObjectClass from '../models/ObjectClass';
import Order from '../models/Order';
import Place from '../models/Place';
import Point from '../models/Point';
import Price from '../models/Price';
import Schedule from '../models/Schedule';
import Subdivision from '../models/Subdivision';
import Transport from '../models/Transport';
import User from '../models/User';
import Report from '../models/Report';
import { REF_STATUS } from '../models/ClassHelper';
import Discount from '../models/Discount';
import { APICode } from '../models/types';
import ReportParams from '../models/ReportParams';
import { saveModel } from '../utils/events';
import OrderDetails from '../models/OrderDetails';

dayjs.extend(utc);
dayjs.extend(timezone);

export const RepositoryContext = createContext();

const initialState = {
  'Account': {},
  'AccountObject': {},
  'Area': {},
  'Category': {},
  'City': {},
  'Country': {},
  'Discount': {},
  'Excursion': {},
  'FreeService': {},
  'ObjectClass': {},
  'Order': {},
  'Place': {},
  'Point': {},
  'Price': {},
  'Region': {},
  'Schedule': {},
  'Subdivision': {},
  'Transport': {},
  'User': {},
  'OrderDetails': {}
};

const classes = {
  'Account': Account,
  'AccountObject': AccountObject,
  'Area': Area,
  'Category': Category,
  'City': City,
  'Country': Country,
  'Discount': Discount,
  'Excursion': Excursion,
  'FreeService': FreeService,
  'ObjectClass': ObjectClass,
  'Order': Order,
  'Place': Place,
  'Point': Point,
  'Price': Price,
  'Region': Region,
  'Schedule': Schedule,
  'Subdivision': Subdivision,
  'Transport': Transport,
  'User': User,
  'OrderDetails': OrderDetails
};

class Model {
  constructor(id, clazz) {
    this.id = id;
    this.state = REF_STATUS.PENDING;
    this.clazz = clazz;
    this.errors = [];
    this.value = null;
  }

  addError(error) {
    this.errors.push(error);
  }

  getErrors() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
  }
}

export const RepositoryProvider = (props) => {
  const logger = new Logger({ name: 'RepositoryProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common']);

  const [repository, setRepository] = useState(initialState);

  const [serverUnavailableIssue, setServerUnavailableIssue] = useState(false);

  const { callApi } = useAPI();

  const isServerUnavailableIssue = () => {
    return serverUnavailableIssue;
  };

  const action = (api, params, clazz) => {
    clazz = clazz || api.clazz;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await callApi(api, params);
        if (response.error) {
          setServerUnavailableIssue(response.error === APICode.ERR_NETWORK);
          reject(response.error);
        } else {
          setServerUnavailableIssue(false);
          let obj = response.data;

          if (clazz) {
            obj = new clazz(response.data);
            preload(obj);
          }

          logger.debug("Repository::action", obj);
          resolve(obj);
        }
      } catch(e) {
        setServerUnavailableIssue(false);
        reject({ error: e });
      }
    });
  };

  const request = (api, params, clazz) => {
    clazz = clazz || api.clazz;
    let className = clazz.name;
    return new Promise(async (resolve, reject) => {
      if (params && (typeof params === 'object') && (typeof params.id === 'string') && params.id) {
        try {
          const response = await callApi(api, params);
          if (response.error) {
            setServerUnavailableIssue(response.error === APICode.ERR_NETWORK);
            reject(response.error);
          } else {
            setServerUnavailableIssue(false);
            let obj = new clazz(response.data);
            logger.debug("Repository::request", clazz.name, obj);
            preload(obj);
            resolve(obj);
            setRepository({ ...repository, [className]: { ...repository[className], [params.id]: obj } });
            saveModel(params.id, className, obj);
          }
        } catch(e) {
          setServerUnavailableIssue(false);
          reject({ error: e });
        }
      } else {
        reject({ error: 'BAD_REQUEST' });
      }
    });
  };

  const list = async (api, params, clazz) => {
    clazz = clazz || api.clazz;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await callApi(api, params);
        if (response.error) {
          setServerUnavailableIssue(response.error === APICode.ERR_NETWORK);
          reject(response.error);
        } else {
          setServerUnavailableIssue(false);
          let objList = response.data instanceof Array ? response.data.map((schedule) => new clazz(schedule)) : [];
          logger.debug("Repository::list", clazz.name, objList);
          objList.forEach((obj) => { preload(obj); });
          resolve(objList);
        }
      } catch(e) {
        setServerUnavailableIssue(false);
        reject({ error: e });
      }
    });
  };

  const report = async (api, params, clazz) => {
    clazz = clazz || api.clazz;
    return new Promise(async (resolve, reject) => {
      try {
        let reportParams = new ReportParams(params);
        const response = await callApi(api, { ...params, ...reportParams } );
        if (response.error) {
          setServerUnavailableIssue(response.error === APICode.ERR_NETWORK);
          reject(response.error);
        } else {
          setServerUnavailableIssue(false);
          let report = new Report(response.data, clazz);
          logger.debug("Repository::report", clazz.name, report);
          report.rows.forEach((obj) => { preload(obj); });
          resolve(report);
        }
      } catch(e) {
        setServerUnavailableIssue(false);
        reject({ error: e });
      }
    });
  };

  const getModel = async (clazz, id, useNull = false, useLoad = false) => {
    let className = typeof clazz === 'string' ? clazz : clazz.name;
    id = (id !== null && typeof id === 'object') ? id.id : id;
    if (className) {
      let classRepo = repository[className];
      let model = { id };
      if (id !== undefined) {
        if (classRepo && id && classRepo[id] && classRepo[id].value) {
          return classRepo[id].value || (useNull ? null : { id });
        } else if (classRepo && id && (model = await getModel(id, className))) {
          classRepo[id] = model;
          return model;
        } else if (id && useLoad && load[className]) {
          try {
            model = await load[className]({ id });
            return model || (useNull ? null : { id });
          } catch (e) {
            return (useNull ? null : { id });
          }
        } else {
          return (useNull ? null : { id });
        }
      } else {
        return Object.values(classRepo);
      }
    } else {
      return (useNull ? null : (id ? { id } : []));
    }
  };

  const load = {
    'Account': ({ id, accountId }) => {},
    'AccountObject': ({ id, accountId }) => request(API.object.accountObjects, { id, accountId }, AccountObject),
    'Area': ({ id, accountId }) => {},
    'Category': ({ id, accountId }) => {},
    'City': ({ id, accountId }) => {},
    'Country': ({ id, accountId }) => {},
    'Discount': ({ id, accountId }) => request(API.discount.discount, { id, accountId }, Discount),
    'Excursion': ({ id, accountId }) => request(API.excursion.excursion, { id, accountId }, Excursion),
    'FreeService': ({ id, accountId }) => {},
    'ObjectClass': ({ id, accountId }) => request(API.object.accountObjects, { id, accountId }, ObjectClass),
    'Order': ({ id, accountId }) => {},
    'Place': ({ id, accountId }) => request(API.place.place, { id, accountId }, Place),
    'Point': ({ id, accountId }) => request(API.point.point, { id, accountId }, Point),
    'Price': ({ id, accountId }) => request(API.price.price, { id, accountId }, Price),
    'Region': ({ id, accountId }) => {},
    'Schedule': ({ id, accountId }) => {},
    'Subdivision': ({ id, accountId }) => request(API.subdivision.subdivision, { id, accountId }, Subdivision),
    'Transport': ({ id, accountId }) => {},
    'User': ({ id, accountId }) => request(API.user.user, { id, accountId }, User),
    'OrderDetails': ({ id, accountId }) => {},
  }

  const preload = (obj) => {
    let model = null;
    if (obj && typeof obj === 'object') {
      const id = obj.id;
      const className = obj.constructor.name;
      const clazz = classes[className];
      if (id && clazz) {
        if (repository[className] && !repository[className][id]) {
          model = new Model(id, clazz);
          repository[className][id] = model;
          let promise = load[className] && load[className]({ id });
          if (promise) {
            promise.then((o) => {
              repository[className][id].value = o;
              setRepository({ ...repository, [className]: { ...repository[className], [id]: o } });
            }).catch((e) => {
              logger.error(`Preload ERROR [${className}:${id}]`, e);
            });
          }
        }
      }
      for (let name in obj) {
        let field = obj[name];
        if (field && typeof field === 'object') {
          preload(field);
        }
      }
    }
    return model;
  };

  // const getModel = async (className, id) => {
  //   if (repository[className] && !repository[className][id]) {
  //     return load[className]({id});
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       if (repository[className][id]) {
  //         resolve(repository[className][id].value);
  //       } else {
  //         reject();
  //       }
  //     });
  //   }
  // };

  const getValue = async (className, id) => {
    let value = null;
    if (repository[className] && !repository[className][id] && load[className]) {
      value = await load[className]({ id });
    } else if (repository[className] && repository[className][id]) {
      value = repository[className][id].value;
    }

    return value;
  };

  return (
    <RepositoryContext.Provider
      value={{
        action,
        request,
        list,
        report,
        preload,
        getModel,
        getValue,
        isServerUnavailableIssue,
        load: { ...load }
      }}
    >
      {serverUnavailableIssue && (
        <div className="top-error-message">
          {t('common:error.err_network')}
        </div>
      )}
      {props.children}
    </RepositoryContext.Provider>
  );
};

export default RepositoryProvider;