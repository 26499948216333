import React, { useState, createContext, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../server/api';
import Logger from '../utils/logger';
import { STORAGE } from '../defs/storage';
import { getStorageValue, setStorageValue } from '../hooks/useLocalStorage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Point from '../models/Point';
import Schedule from '../models/Schedule';
import User from '../models/User';
import UserSession from '../models/UserSession';
import Price from '../models/Price';
import Place from '../models/Place';
import { RepositoryContext } from './RepositoryProvider';
import OrderSession from '../models/OrderSession';
import ExcursionPrice from '../models/ExcursionPrice';
import ClientDetails from '../models/ClientDetails';
import log from '../utils/logger';
import Order from '../models/Order';

export const UserContext = createContext();

dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  session: getStorageValue(STORAGE.SESSION, null, UserSession),
  orderSession: getStorageValue(STORAGE.ORDER_SESSION, null, OrderSession),
  user: null,
  scheduleDates: [],
  scheduleTimes: [],
  schedules: [],
  points: [],
  places: [],
  prices: [],
};

export const UserProvider = (props) => {
  const logger = new Logger({ name: 'UserProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common', 'account']);

  const [userInfo, setUserInfo] = useState(initialState);

  const repository = useContext(RepositoryContext);

  const fetch = {
    user: async () => {
      let user = null;
      try {
        const response = await repository.action(API.user.whoami);
        if (response.error) {
          //TODO:: handle error
        } else {
          user = setUser(response);
          if (user) {
            let userSession = getSession();
            let oldAccountSession = { ...userSession };
            let newAccount = userSession.account;

            if (user.accounts.length) {
              let account = null;
              if (userSession.account) {
                account = user.accounts.find((account) => account.account === userSession.account);
              }
              if (!account) {
                account = user.accounts[0];
              }
              newAccount = !account || !account.account || newAccount !== account.account.id;

              setSessionAccount((account && account.account && account.account.id) || null);
              if (account && account.account && oldAccountSession.account === account.account.id && account.roles.indexOf(oldAccountSession.role) >= 0) {
                setSessionRole(oldAccountSession.role);
              } else {
                setSessionRole((account && account.roles && account.roles[0]) || null);
              }
            } else {
              newAccount = true;
              setSessionAccount(null);
              setSessionRole(null);
            }

            if (newAccount) {
              setSessionPoint(null);
              setSessionSubdivision(null);
              loadPoints();
            } else {
              setSessionPoint(userSession.point);
              setSessionSubdivision(userSession.subdivision);
            }
          } else {
            setSessionAccount(null);
            setSessionRole(null);
            setSessionPoint(null);
            setSessionSubdivision(null);
          }
        }
      } catch(e) {
        log.error(e);
      }
      return user;
    },

    points: async () => {
      let report = await repository.report(API.point.report, { accountId: getSession().account, id: getSession().subdivision }, Point);
      return report.rows;
    },

    schedules: async (day) => {
      let schedules = [];
      try {
        let date = dayjs().format('YYYY-MM-DD');
        if (day) {
          date = dayjs(day).format('YYYY-MM-DD');
        }

        let subdivision = getSession().subdivision;

        if (subdivision) {
          const response = await repository.action(API.excursion.excursions, {date: date, timezone: dayjs.tz.guess(), subdivision});
          if (response.error) {
            //TODO:: handle error
          } else {
            schedules = setSchedules(response);
          }
        }
      } catch(e) {
        log.error(e);
      }
      return schedules;
    },

    scheduleDates: (excursionId, subdivisionId) => {
      return repository.action(API.schedule.datesSeats, { accountId: getSession().account, id: excursionId, subdivision: subdivisionId }, String).then((dates) => {
        setScheduleDates(dates);
        return dates;
      });
    },

    scheduleTimes: (excursionId, day) => {
      return repository.list(API.schedule.times, { id: excursionId, date: dayjs(day || undefined).format('YYYY-MM-DD'), accountId: getSession().account }, Object).then((times) => {
        setScheduleTimes(times);
        return times;
      });
    },

    price: async (id) => {
      let price = null;
      try {
        const response = await repository.action(API.price.price, {id});
        if (response.error) {
          //TODO:: handle error
        } else {
          price = new Price(response);
          setPlaces([ ...userInfo.prices, price ]);
        }
      } catch(e) {
        log.error(e);
      }
      return price;
    },

    place: async (id) => {
      let place = null;
      try {
        const response = await repository.action(API.place.place, {id});
        if (response.error) {
          //TODO:: handle error
        } else {
          place = new Place(response);
          setPlaces([ ...userInfo.places, place ]);
        }
      } catch(e) {
        log.error(e);
      }
      return place;
    }
  };

  useEffect(() => {
    getPoints();
  }, [userInfo.session.account]);

  const reset = () => {
    setUserInfo(initialState);
  };


  const setSession = (prop = null) => {
    let session = prop === null ? null : prop instanceof UserSession ? prop : new UserSession({ ...userInfo.session, ...prop });
    setStorageValue(STORAGE.SESSION, session);
    setUserInfo((prevState) => { prevState.session = session; return prevState; });
  };

  const setSessionAccount = (prop = null) => {
    if (prop !== userInfo.session.account) {
      userInfo.session.account = prop || null;
      userInfo.session.point = null;
      userInfo.session.role = null;
      userInfo.session.subdivision = null;
      setStorageValue(STORAGE.SESSION, userInfo.session);
    }
  };

  const setSessionRole = (prop = null) => {
    userInfo.session.role = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setSessionPoint = (prop = null) => {
    userInfo.session.point = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setSessionSubdivision = (prop = null) => {
    userInfo.session.subdivision = prop || null;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const setLoggedIn = (logged_in) => {
    userInfo.session.logged_in = !!logged_in;
    setStorageValue(STORAGE.SESSION, userInfo.session);
  };

  const getSession = () => {
    userInfo.session = getStorageValue(STORAGE.SESSION, null, UserSession);
    return userInfo.session;
  };

  const isLoggedIn = () => {
    let session = getSession();
    return session.logged_in;
  };

  const resetSession = () => {
    let userSession = new UserSession();
    setUserInfo((prevState) => { prevState.session = userSession; return prevState; });
    setStorageValue(STORAGE.SESSION, userSession);
  };


  const setOrderSession = (prop = null) => {
    let orderSession = prop === null ? null : prop instanceof OrderSession ? prop : new OrderSession({ ...userInfo.orderSession, ...prop });
    setStorageValue(STORAGE.ORDER_SESSION, orderSession);
    setUserInfo((prevState) => { prevState.orderSession = orderSession; return prevState; });
  };

  const getOrderSession = () => {
    userInfo.orderSession = getStorageValue(STORAGE.ORDER_SESSION, null, OrderSession);
    return userInfo.orderSession;
  };

  const resetOrderSession = () => {
    let orderSession = new OrderSession();
    userInfo.orderSession = orderSession;
    setUserInfo((prevState) => { prevState.orderSession = orderSession; return prevState; });
    setStorageValue(STORAGE.ORDER_SESSION, orderSession);
  };


  const setUser = (prop = null) => {
    let user = prop === null ? null : prop instanceof User ? prop : new User({ ...userInfo.user, ...prop });
    setUserInfo((prevState) => { prevState.user = user; return prevState; });
    return user;
  };

  const getUser = async () => {
    let user = userInfo.user;
    if ((user === null || !user.isValid()) && isLoggedIn()) {
      user = await loadUser();
    }
    return user;
  };

  const loadUser = async () => {
    let user = await fetch.user();
    setUser(user);
    await loadPoints();
    return user;
  };

  const loadPoints = async () => {
    let points = [];
    try {
      if (getSession().account) {
        points = await fetch.points();
        if (points) {
          setPoints(points);
          let _point = points.find((p) => p.id === getSession().point);
          if (!getSession().point || !_point) {
            setSessionPoint(points.length ? points[0].id : null);
            setSessionSubdivision(points.length ? points[0].subdivision.id : null);
          }
        }
      }
    } catch(e) {
      logger.error(e);
    }
    return points;
  };


  const setSchedules = (prop = []) => {
    let schedules = prop === null ? [] : prop.map((schedule) => new Schedule(schedule));
    setUserInfo((prevState) => { prevState.schedules = schedules; return prevState; });
    return schedules;
  };

  const getSchedules = () => {
    return userInfo.schedules;
  };


  const setPrices = (prop = []) => {
    let prices = prop === null ? [] : prop.map((price) => new ExcursionPrice(price));
    setUserInfo((prevState) => { prevState.prices = prices; return prevState; });
    return prices;
  };

  const getPrices = () => {
    return userInfo.prices;
  };


  const setScheduleDates = (prop = []) => {
    let scheduleDates = prop === null ? [] : prop.map((scheduleDates) => new Schedule(scheduleDates));
    setUserInfo((prevState) => { prevState.scheduleDates = scheduleDates; return prevState; });
    return scheduleDates;
  };

  const getScheduleDates = async (day) => {
    return userInfo.scheduleDates;
  };


  const setScheduleTimes = (prop = []) => {
    let scheduleTimes = prop === null ? [] : prop.map((schedulTimee) => new Schedule(schedulTimee));
    setUserInfo((prevState) => { prevState.scheduleTimes = scheduleTimes; return prevState; });
    return scheduleTimes;
  };

  const getScheduleTimes = async (day) => {
    return userInfo.scheduleTimes;
  };


  const setPoints = (prop = []) => {
    let points = prop === null ? [] : prop.map((point) => new Point(point))
    setUserInfo((prevState) => { prevState.points = points; return prevState; });
    return points;
  };

  const getPoints = () => {
    return userInfo.points;
  };


  const setPlaces = (prop = []) => {
    let places = prop === null ? [] : prop.map((place) => new Place(place))
    setUserInfo((prevState) => { prevState.places = places; return prevState; });
    return places;
  };


  const getMyOrders = async (params) => {
    return repository.list(API.cashier.orders, params, Order).then((rows) => {
      return rows || [];
    });
  };

  const autocompleteOrderClients = async (query) => {
    return repository.list(API.order.autocomplete, { query }, ClientDetails).then((clients) => {
      return clients || [];
    });
  };

  const signIn = async (phone, password) => {
    let answer = null;
    try {
      const response = await repository.action(API.auth.signIn, { phone, password });
      if (response) {
        if (response.error) {
          answer = { value: phone, error: t(`common:error.${response.error.toLowerCase()}`) };
        } else {
          setLoggedIn(true);
          let user = await getUser();
          if (user && user.isValid()) {
            answer = { value: phone, error: false };
          } else {
            answer = { value: phone, error: t('common:error.user_not_found')};
          }
        }
      } else {
        answer = { value: phone, error: t('common:error.unknown_response')};
      }
    } catch(e) {
      answer = { value: phone, error: t(`common:error.${e.toLowerCase()}`) };
    }
    return answer;
  };


  const signOut = async () => {
    setLoggedIn(false);
    resetSession();
    await repository.action(API.auth.signOut);
    setUser(null);
    resetOrderSession();
  };


  return (
    <UserContext.Provider
      value={{
        reset,

        setSession,
        setSessionAccount,
        setSessionPoint,
        setSessionRole,
        setSessionSubdivision,
        setLoggedIn,
        getSession,
        isLoggedIn,
        resetSession,

        setOrderSession,
        getOrderSession,
        resetOrderSession,

        setUser,
        getUser,
        setSchedules,
        getSchedules,
        setScheduleDates,
        getScheduleDates,
        setScheduleTimes,
        getScheduleTimes,
        setPoints,
        getPoints,
        setPrices,
        getPrices,
        getMyOrders,
        autocompleteOrderClients,

        loadUser,
        loadPoints,

        signIn,
        signOut,
        fetch
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
