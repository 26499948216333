import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const InputAutocomplete = ({ value = '', placeholder, list, className = '', onSelect, onChange, onInput }) => {
  const [suggestions, setSugesstions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [text, setText] = useState(value);

  const handleInput = (e) => {
    let reg = new RegExp(e.target.value.replace('+', '\\+'), 'i');
    let founded = list.filter((item) => reg.test(item));

    setText(e.target.value);
    setSugesstions(founded);
    setShowSuggestion(founded.length > 0);

    typeof onInput === 'function' && onInput(e.target.value);
  };

  const handleChange = (e) => {
    setText(e.target.value);

    typeof onChange === 'function' && onChange(e.target.value);
  };

  const handleSelect = (value) => {
    setText(value);
    setShowSuggestion(false);

    typeof onSelect === 'function' && onSelect(value);
    typeof onChange === 'function' && onChange(value);
  };

  const closeSuggestion = () => {
    setShowSuggestion(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeSuggestion);

    return () => {
      document.removeEventListener('click', closeSuggestion);
    };
  }, []);

  useEffect(() => {
    setSugesstions(list);
    setShowSuggestion(list.length > 0);
  }, [list]);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <span className={`sugesstion-auto ${className}`}>
      <input className="form-control" placeholder={placeholder} type="search" value={text} onBlur={handleChange} onInput={handleInput} />
      {showSuggestion && (
        <div className="suggestions">
          {suggestions.map((item, idx) => (
            <div key={idx} onClick={() => handleSelect(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </span>
  );
}

InputAutocomplete.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func
};

export default InputAutocomplete;