import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { format as formatDate, formatRelative, formatDistance, isDate } from 'date-fns';
import { ru } from 'date-fns/locale';
import log from './utils/logger';

import { RepositoryProvider } from './store/RepositoryProvider';
import { UserProvider } from './store/UserProvider';

import common_ru from './translations/ru/common.json';
import auth_ru from './translations/ru/auth.json';
import account_ru from './translations/ru/account.json';
import excursion_ru from './translations/ru/excursion.json';
import new_order_ru from './translations/ru/new_order.json';
import sale_ru from './translations/ru/sale.json';
import schedule_ru from './translations/ru/schedule.json';
import boarding_ru from './translations/ru/boarding.json';
import cashbox_ru from './translations/ru/cashbox.json';
import salary_ru from './translations/ru/salary.json';
import route_ru from './translations/ru/route.json';
import collection_ru from './translations/ru/collection.json';
import ticket_ru from './translations/ru/ticket.json';

const locales = { ru };

i18next.init({
  lng: 'ru',
  resources: {
    ru: {
      common: common_ru,
      auth: auth_ru,
      account: account_ru,
      excursion: excursion_ru,
      new_order: new_order_ru,
      sale: sale_ru,
      schedule: schedule_ru,
      boarding: boarding_ru,
      cashbox: cashbox_ru,
      salary: salary_ru,
      route: route_ru,
      collection: collection_ru,
      ticket: ticket_ru
    },
  },
  interpolation: {
    escapeValue: false,

    format: (value, format, lng) => {
      let text = value;

      if (isDate(value)) {
        const locale = locales[lng];

        if (format === "short") {
          text = formatDate(value, "P", { locale });
        } else if (format === "long") {
          text = formatDate(value, "PPPP", { locale });
        } else if (format === "relative") {
          text = formatRelative(value, new Date(), { locale });
        } else if (format === "ago") {
          text = formatDistance(value, new Date(), { locale, addSuffix: true });
        } else {
          text = formatDate(value, format, { locale });
        }
      }

      return text;
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <RepositoryProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </RepositoryProvider>
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(log.info);
