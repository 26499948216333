import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { MODAL_TYPE } from './Modal';
import Report from '../models/Report';

const KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13
};

const SelectAutocomplete = ({ value, className = '', template, field = 'title', apiHandler, onChange }) => {
  const [showAutocompleteModal, setShowAutocompleteModal] = useState(false);

  const [report, setReport] = useState(new Report());
  const [selectedRow, setSelectedRow] = useState(-1);
  const [item, setItem] = useState({});

  const queryRef = useRef();
  const valueRef = useRef();

  const handleInput = (event) => {
    let row = selectedRow;
    if (event.keyCode === KEYS.DOWN) {
      ++row;
      setSelectedRow((row < 0 || row >= report.rows.length) ? 0 : row);
    } else if (event.keyCode === KEYS.UP) {
      --row;
      setSelectedRow(row < 0 ? (report.rows.length ? (report.rows.length - 1) : -1) : row);
    } else if (event.keyCode === KEYS.ENTER) {
      if (selectedRow >= 0 && selectedRow < report.rows.length) {
        queryRef.current.value = report.rows[selectedRow][field];
      }
    }
  };

  const handleAutocomplete = useCallback(async (query) => {
    let filters = query ? { [field]: { $regex: query, $options: 'i' } } : {};
    let reportFilters = new Report({filters});
    let _report = await apiHandler(reportFilters);
    setReport(_report);
  }, []);

  const handleCloseAutocompleteModal = useCallback(() => {
    setShowAutocompleteModal(false);
  }, []);

  const handleApplyValue = useCallback(() => {
    let row = selectedRow;
    if (row < 0 && report.rows.length > 0) {
      row = 0;
    }

    if (row >= 0) {
      onChange(report.rows[row]);
      setItem(report.rows[row] || {});
      valueRef.current.value = report.rows[row][field];
    } else {
      setItem({});
    }

    setShowAutocompleteModal(false);
  }, [selectedRow]);

  const handleShowModal = useCallback(() => {
    setShowAutocompleteModal(true);
    if (queryRef.current) {
      queryRef.current.value = '';
    }
  }, [showAutocompleteModal]);

  const handleSelectRow = useCallback((item, i, id, value) => {
    onChange(item || {});
    valueRef.current.value = value;
    setItem(item || {});
    setSelectedRow(i);
    setShowAutocompleteModal(false);
  }, []);

  useEffect(() => {
    handleAutocomplete('');
  }, []);

  useEffect(() => {
    setItem({[field]: value});
  }, [value]);

  return (
    <>
      <div className={`input-group ${className}`}>
        <input ref={valueRef} type="text" readOnly={true} value={item[field]} className="form-control"/>
        <span className="input-group-text" onClick={handleShowModal}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{width: '24px', height: '24px'}} viewBox="0 0 16 16" fill="#000000"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>
        </span>
      </div>
      <Modal show={showAutocompleteModal} type={MODAL_TYPE.INPUT} onHide={handleCloseAutocompleteModal}>
        <Modal.Body>
          <div className="input-group">
            <input className="form-control" ref={queryRef} autoFocus onInput={(e) => handleAutocomplete(e.target.value)} onKeyDown={handleInput}/>
            <span className="input-group-text" onClick={handleApplyValue}>OK</span>
          </div>
          <div>
            {report.rows.map(((row, i) => (
              <div className={selectedRow === i ? 'selected-row' : ''} key={i} id={row.id} onClick={(e) => {handleSelectRow(row, i, row.id, row[field]);}}>
                {template ? template(row, i) : row[field]}
              </div>
            )))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

SelectAutocomplete.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  apiHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  template: PropTypes.any,
  field: PropTypes.string
};

export default SelectAutocomplete;