import axios from 'axios';
import Logger from '../utils/logger';
import { FetchMethod } from './useAPI';
import { APICode } from '../models/types';
import networkState from '../utils/networkState';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_URL}/${process.env.REACT_APP_TRIP_COMPANY_API_SERVER_VERSION}/`.replace(/(?<!(http:|https:))\/\//g, '/'),
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common.Accept = '*/*';
// instance.defaults.headers.common.Accept = 'text/plain';
instance.defaults.headers.common['x-service'] = 'trip-company';
instance.defaults.withCredentials = true;

const useFetch = (setIsLoading = () => {}) => {
  const logger = new Logger({ name: 'useFetch', showDate: false, showFile: false });

  const callApi = async (api, apiParams) => {
    let params = apiParams instanceof FormData ? apiParams : Object.assign({}, apiParams);
    logger.debug('Fetch:', api);
    if (!api || typeof api !== 'object') {
      return { error: APICode.UNKNOWN_REQUEST };
    }

    setIsLoading(true);

    let response = null;
    try {
      const config = { withCredentials: true };
      // if (api.auth) {
      //   let authSession = getStorageValue(STORAGE.SESSION, null, User);
      //   if (!authSession || !authSession.account) {
      //     setIsLoading(false);
      //     return { error: APICode.UNAUTHORIZED }
      //   }
      // }

      let url = api.url;
      for (const param of api.uriVars) {
        let key = param.slice(1);
        const re = new RegExp(`${param}\\b`, 'gi');
        if (re.test(url)) {
          url = url.replace(re, params[key] || '');
          delete params[key];
        }
      }

      if (params instanceof FormData) {
        config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data' };
        if (networkState.isAvailable()) {
          params.append('nocache', new Date().getTime());
        }
      } else if (networkState.isAvailable()) {
        params.nocache = new Date().getTime();
      }

      response = await instance[api.method || 'post'](url, api.method === FetchMethod.GET ? {params} : params, config);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        response = error.response;
        logger.error('Error:', error.config.url, error.config.data, response.data);
        return { error: response.data.error };
      } else if (error.code) {
        logger.error('Error:', error.config.url, error.config.data, error.code);
        return { error: error.code };
      } else {
        logger.error('Error:', error.config.url, error.config.data, APICode.UNKNOWN_RESPONSE);
        return { error: APICode.UNKNOWN_RESPONSE };
      }
    }

    logger.debug('Response:', response);
    if (response) {
      return response;
    } else {
      return { error: APICode.UNKNOWN_RESPONSE };
    }
  };

  return {
    callApi
  };
}

export default useFetch;