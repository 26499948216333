import { Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapButton from 'react-bootstrap/Button';
import BootstrapButtonGroup from 'react-bootstrap/ButtonGroup'
import BootstrapContainer from 'react-bootstrap/Container';
import BootstrapRow from 'react-bootstrap/Row';
import BootstrapCol from 'react-bootstrap/Col';
import BootstrapToggleButton from 'react-bootstrap/ToggleButton';
import BootstrapToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

export const BUTTON_TYPE = {
  PRIMARY: 'dark',
  SECONDARY: 'light',
  SELECTED: 'light',
};

export const BUTTON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  FLOAT: 'float',
  NONE: 'none'
};

export const TOGGLE_TYPE = {
  RADIO: 'radio',
  CHECK: 'check'
};

const Button = ({ type = BUTTON_TYPE.PRIMARY, position = BUTTON_POSITION.NONE, className = '', onClick, disabled, children }) => {
  return (
    <>
      {(position === BUTTON_POSITION.LEFT || position === BUTTON_POSITION.RIGHT || position === BUTTON_POSITION.FLOAT) && (
        <BootstrapCol>
          <BootstrapButton variant={type} onClick={onClick} disabled={disabled}>{children}</BootstrapButton>
        </BootstrapCol>
      )}
      {(position !== BUTTON_POSITION.LEFT && position !== BUTTON_POSITION.RIGHT && position !== BUTTON_POSITION.FLOAT) && (
        <BootstrapButton variant={type} className={className} onClick={onClick} disabled={disabled}>{children}</BootstrapButton>
      )}
    </>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

const Group = ({ children }) => {
  return (
    <BootstrapButtonGroup>
      {children}
    </BootstrapButtonGroup>
  );
};

Button.Group = Group;

const Panel = ({ children }) => {
  const childList = Children.toArray(children);
  let leftButtons = [];
  let floatButtons = [];
  let rightButtons = [];
  let idx = 0;
  if (childList) {
    Children.map(childList, (child) => {
      if (child.props.position === BUTTON_POSITION.LEFT) {
        leftButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      } else if (child.props.position === BUTTON_POSITION.RIGHT) {
        rightButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      } else {
        floatButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      }
    });
  }

  if (leftButtons.length === 1 && rightButtons.length <= 0 && floatButtons.length <= 0) {
    floatButtons.push(<BootstrapCol key={idx++}></BootstrapCol>);
  }

  if (leftButtons.length <= 0 && rightButtons.length === 1 && floatButtons.length <= 0) {
    floatButtons.unshift(<BootstrapCol key={idx++}></BootstrapCol>);
  }

  return (
    <BootstrapContainer className="button-panel">
      <BootstrapRow>
        {leftButtons}
        {floatButtons}
        {rightButtons}
      </BootstrapRow>
    </BootstrapContainer>
  );
};

Button.Panel = Panel;

const Toggle = ({ name, value = [], checked, type = TOGGLE_TYPE.RADIO, className = '', onChange, children }) => {
  return (
    <BootstrapToggleButton type={type} className={className} variant={checked ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY} name={name} value={value} checked={checked} onChange={(e) => onChange(e.currentTarget.value)}>
      {children}
    </BootstrapToggleButton>
  );
};

Toggle.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  type: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

Button.Toggle = Toggle;

const ToggleGroup = ({ type = 'checkbox', value = [], onChange, children }) => {
  return (
    <BootstrapToggleButtonGroup type={type} value={value} onChange={onChange}>
      {children}
    </BootstrapToggleButtonGroup>
  );
};

Toggle.propTypes = {
  type: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func
};

Button.ToggleGroup = ToggleGroup;

export default Button;