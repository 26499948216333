export const arrayToMap = (arr, key = 'id') => {
  return Object.fromEntries(arr.map(o => [o[key], o]));
};


export const _times = (count, func) => {
  func = typeof func === 'function' ? func : () => {};
  const arr = [];
  if (typeof count === 'number' && count) {
    for (let i = 0; i < count; ++i) {
      arr.push(null);
    }
  }

  return arr.map(func);
};

export const createArray = (count, template = null) => {
  const arr = [];
  if (typeof count === 'number' && count) {
    for (let i = 0; i < count; ++i) {
      arr.push(template !== null && typeof template === 'object' ? { ...template } : template);
    }
  }

  return arr;
};

export const isMediaDevicesSupported = () => {
  const isMediaDevicesSupported = typeof navigator !== "undefined" && !!navigator.mediaDevices;

  if (!isMediaDevicesSupported) {
    console.warn(`[ReactQrReader]: MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"`);
  }

  return isMediaDevicesSupported;
};

export const isValidType = (value, name, type) => {
  const isValid = typeof value === type;

  if (!isValid) {
    console.warn(`[ReactQrReader]: Expected "${name}" to be a of type "${type}".`);
  }

  return isValid;
};
