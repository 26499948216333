import { useTranslation } from 'react-i18next';
import Image from '../../components/Image';
import NavBar from '../../components/NavBar';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Table, { VERTICAL_ALIGN } from '../../components/Table';
import { UserContext } from '../../store/UserProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IMAGE_SIZES } from '../../models/Image';
import Input, { ACCEPT_FILE_FORMAT } from '../../components/Input';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import Field from '../../components/Field';
import InputWithError from '../../components/InputWithError';
import Section from '../../components/Section';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import DateFormat from '../../components/DateFormat';
import File from '../../models/File';
import ProfileIcon from '../../components/icons/Profile';

const Profile = () => {
  const {t} = useTranslation(['common', 'account']);

  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [photo, setPhoto] = useState(null);
  const [file, setFile] = useState(null);
  const [avatarPath, setAvatarPath] = useState(null);
  const [name, setName] = useState('');
  const [birth, setBirth] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [comparePasswordError, setComparePasswordError] = useState('');
  const [changePasswordError, setChangePasswordError] = useState('');

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const loadUser = async () => {
    const user = await userInfo.getUser();
    setUser(user);
    setProfile(user ? user.profile : null);
    setPhoto(user ? user.profile.avatar : null);
    setName(user ? user.profile.fullName : '');
    setBirth(user ? user.profile.birthday : null);
    setAvatarPath(user && user.profile.avatar.getUrl(IMAGE_SIZES.avatar.sizes.w256.width));
  };

  const updateUser = useCallback(async () => {
    const [firstName = '', lastName = '', middleName = ''] = name.split(/[ \.,\t]+/);
    const params = {
      avatar: avatarPath,
      firstName,
      lastName,
      middleName,
      birthday: DateFormat.Request({ date: birth })
    };

    if (file) {
      const newAvatar = await uploadAvatar();
      if (newAvatar.path) {
        params.avatar = newAvatar.path;
      }
    }

    await repository.action(API.user.updateUser, params);
  }, [birth, name, avatarPath]);

  const handleSave = () => {
    if (user && (birth !== user.profile.birthday || file || name !== user.profile.fullName)) {
      updateUser();
    }
    if (password && newPassword) {
      changePassword();
    }
  };

  const changePassword = useCallback(async () => {
    const params = {
      currentPassword,
      newPassword
    };

    if (password === newPassword) {
      repository.action(API.user.changePassword, params).then(() => {
        setCurrentPassword('');
        setPassword('');
        setNewPassword('');
      }).catch((e) => {
        setChangePasswordError(t(`account:profile.password.${e.toLowerCase()}`));
      });
    } else {
      setComparePasswordError(t('account:profile.password.error_compare_password'));
    }
  }, [currentPassword, password, newPassword]);

  const uploadAvatar = useCallback(async () => {
    let formData = new FormData();
    formData.append("avatar", file);
    const newAvatar = await repository.action(API.user.uploadAvatar, formData, File);
    setPhoto(newAvatar);
    setAvatarPath(newAvatar.path ? newAvatar.getUrl(IMAGE_SIZES.avatar.sizes.w256.width) : '');
    return newAvatar;
  }, [birth, name, avatarPath]);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (file && FileReader) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarPath(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (user && user.profile) {
      setAvatarPath(user.profile.getAvatar(IMAGE_SIZES.avatar.sizes.w256.width));
    } else {
      setAvatarPath('');
    }
  }, [file]);

  return (
    <div className="trip-app">
      <NavBar title={t('account:profile.title')} />
      <div className="trip-app-body">
        <Table>
          <Table.Row>
            <Table.Cell>
              <Image className="profile-card-image" src={avatarPath} width={IMAGE_SIZES.avatar.sizes.w256.width} height={IMAGE_SIZES.avatar.sizes.w256.height} notfound={<ProfileIcon className="profile-card-image" />} />
            </Table.Cell>
            <Table.Cell verticalAlign={VERTICAL_ALIGN.CENTER}>
              <Input.File type={BUTTON_TYPE.PRIMARY} onChange={setFile} label={t('account:button.change_photo')} accept={ACCEPT_FILE_FORMAT.IMAGE} />
            </Table.Cell>
          </Table.Row>
        </Table>

        <Input value={name} label={t('account:profile.name')} onChange={setName} readOnly={profile.approved}/>
        <Field title={t('account:profile.birth')}>
          <SelectDate mode={DAY_PICKER_MODE.SINGLE} selected={birth} onChange={setBirth} readOnly={profile.approved} />
        </Field>

        <Section title={t('account:profile.password.change_password')} visible={false}>
          <InputWithError type="password" value={currentPassword} label={t('account:profile.password.current')} onChange={setCurrentPassword} error={changePasswordError}/>
          <InputWithError type="password" value={password} label={t('account:profile.password.new')} onChange={setPassword}/>
          <InputWithError type="password" value={newPassword} label={t('account:profile.password.repeat')} onChange={setNewPassword} error={comparePasswordError}/>
        </Section>

        <Button.Panel>
          <Button type={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleSave}>{t('account:button.save')}</Button>
        </Button.Panel>
      </div>
    </div>
  )
}

export default Profile;