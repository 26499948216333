import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../components/Field';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import NavBar from '../../components/NavBar';
import InfoDescription from '../../components/InfoDescription';
import Price from '../../components/Price';
import Table from '../../components/Table';
import DateFormat from '../../components/DateFormat';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import log from '../../utils/logger';
import Info, { INFO_VIEW_MODE } from '../../components/Info';
import NewCollection from './new_collection';
import Modal from '../../components/Modal';

const Collections = () => {
  const {t} = useTranslation(['common', 'collection']);

  const repository = useContext(RepositoryContext);

  const [range, setRange] = useState({ from: new Date(), to: new Date() });
  const [totalAmount, setTotalAmount] = useState(0);
  const [collections, setCollections] = useState([]);

  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false);

  const handleCloseNewCollectionModal = useCallback(() => {
    setShowNewCollectionModal(false);
  }, []);

  const loadCollections = useCallback(async () => {
    repository.list(API.collectors.collections, { fromDate: DateFormat.Request({ date: range ? range.from : null }), toDate: DateFormat.Request({ date: range ? range.to : null }) }).then((rows) => {
      setCollections(rows);
      setTotalAmount(rows.reduce((amount, collection) => amount + collection.amount, 0));
    }).catch((e) => {
      log.error(e);
      setCollections([]);
      setTotalAmount(0);
    });
  }, [range]);

  useEffect(() => {
    loadCollections();
  }, [range]);

  return (
    <div className="trip-app">
      <NavBar title={(
        <>
          {t('collection:collections.title')}
          <span className={'title-disabled'} style={{paddingLeft: '20px'}} onClick={() => setShowNewCollectionModal(true)}>{t('collection:collections.new')}</span>
        </>
      )} />
      <div className="trip-app-body">
        <Field title={t('collection:collections.dates')}>
          <SelectDate mode={DAY_PICKER_MODE.RANGE} selected={range} onChange={setRange} />
        </Field>

        <InfoDescription description={t('collection:collections.total')} delim={true}>
          <Price currency="rub" value={totalAmount} />
        </InfoDescription>

        <Table cols={2}>
          {collections.map((collection, i) => (
            <Fragment key={i}>
              <Table.Row>
                <Table.Cell colspan={2}>
                  <DateFormat.DateTime date={collection.date} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Info mode={INFO_VIEW_MODE.ONE} title={t('collection:collections.amount')}>
                    <Price currency="rub" value={collection.amount}/>
                    </Info>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Info mode={INFO_VIEW_MODE.ONE} title={t('collection:collections.cashier')}>{collection.user.profile.fullName}</Info>
                </Table.Cell>
              </Table.Row>
              <Table.Row delim={true}/>
            </Fragment>
          ))}
        </Table>

        <Modal show={showNewCollectionModal} onHide={handleCloseNewCollectionModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {showNewCollectionModal && <NewCollection onCancel={handleCloseNewCollectionModal} />}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Collections;