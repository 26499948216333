import React from 'react';
import PropTypes from 'prop-types';

class OneTimeCode extends React.Component {
  otc_list = new Array(this.props.length).fill(null);

  getValue() {
    let value = '';
    this.otc_list.forEach((v) => {
      value += v.value;
    });
    return value;
  }

  render() {
    const list = []

    const handleInputField =({ target }) => {
      const value = target.value.slice(0, 1);

      const step = value ? 1 : -1;
      const fieldIndex = [...this.otc_list].findIndex((field) => field === target);
      const emptyIndex = [...this.otc_list].findIndex((field) => field.value === '');
      const focusToIndex = (emptyIndex >= 0 ? Math.min(fieldIndex, emptyIndex) : fieldIndex) + step;

      if (emptyIndex >= 0 && emptyIndex < fieldIndex) {
        this.otc_list[emptyIndex].value = value;
        target.value = '';
      } else {
        target.value = value;
      }

      if (focusToIndex < 0 || focusToIndex >= this.otc_list.length) return;

      this.otc_list[focusToIndex].focus();
    };

    const handleFocusField = ({ target }) => {
      target.select();
    };

    this.otc_list.forEach((v, i) => {
      list.push(<span key={i}><input ref={elem => this.otc_list[i] = elem} type="text" placeholder="•" inputMode="numeric" pattern="\d{1}" maxLength={1} onInput={handleInputField} onFocus={handleFocusField}/></span>);
    });

    return (
      <div className={`one-time-code ${this.props.className || ''}`}>
        {list}
      </div>
    );
  }
};

OneTimeCode.propTypes = {
  name: PropTypes.string,
  length: PropTypes.number.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default OneTimeCode;