import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';

const UIExample = () => {
  const {t} = useTranslation(['common']);

  const [checkBoxTest1, setCheckBoxTest1] = useState({ '1': false });
  const [checkBoxTest2, setCheckBoxTest2] = useState([{ title: 'Check 1', value: '1', checked: false }, { title: 'Check 2', value: '2', checked: true }, { title: 'Check 3', value: '3', checked: false }]);

  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleCloseReviewModal = useCallback(() => {
    setShowReviewModal(false);
  }, []);

  const handleCheckBoxTest1 = (value, checked) => {
    setCheckBoxTest1({ ...checkBoxTest1, [value]: checked});
  };

  return (
    <div className="trip-app">
      <div className={`trip-app-header`}>
        {t('common:navbar.ui_demo')}
      </div>
      <div className="trip-app-body">
        <div className="row"></div>
      </div>
      <div className="trip-app-footer">
        {Object.keys(checkBoxTest1).map((k) => (
          <Checkbox key={k} title={`TEST ${k}`} value={k} onChange={handleCheckBoxTest1} checked={checkBoxTest1[k]} />
        ))}

        <Checkbox.Group list={checkBoxTest2} onChange={(value, checked) => {setCheckBoxTest2(checkBoxTest2.map((v) => (v.value === value ? { ...v, checked } : v)));}} />

        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} disabled={false} onClick={() => {setShowReviewModal(true);}}>{t('common:button.review')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} type={BUTTON_TYPE.SECONDARY} onClick={() => {}}>{t('common:button.cancel')}</Button>
        </Button.Panel>

        <Modal show={showReviewModal} onHide={handleCloseReviewModal}>
          <Modal.Header closeButton>
            {t('common:title.info')}
          </Modal.Header>
          <Modal.Body>
            {Object.keys(checkBoxTest1).map((k) => (
              <Checkbox.Result key={k} title={`TEST ${k}`} value={k} checked={checkBoxTest1[k]} />
            ))}
            {Object.keys(checkBoxTest2).map((k) => (
              <Checkbox.Result key={k} title={checkBoxTest2[k].title} value={checkBoxTest2[k].value} checked={checkBoxTest2[k].checked} />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button.Panel>
              <Button position={BUTTON_POSITION.RIGHT} type={BUTTON_TYPE.SECONDARY} onClick={handleCloseReviewModal}>{t('common:button.alright')}</Button>
            </Button.Panel>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default UIExample;