import { PaymentMethod } from '../models/types';
import log from './logger';

export const callToDevice = (func, ...args) => {
  return new Promise(async (resolve, reject) => {
    if (window.AndroidInterface && typeof window.AndroidInterface[func] === 'function') {
      log.info(`callToDevice::${func}(${args})`);
      try {
        resolve(window.AndroidInterface[func](...args));
      } catch (e) {
        log.error(e);
        reject(e);
      }
    } else {
      reject(false);
    }
  });
};

export const removeAndroidEvent = (event) => {
  document.applicationEventHandlers = document.applicationEventHandlers || {};

  if (document.applicationEventHandlers[event]) {
    const { handler, capture } = document.applicationEventHandlers[event];
    document.removeEventListener(event, handler, capture);

    delete document.applicationEventHandlers[event];
  }
};

export const attachAndroidEvent = (event, handler, capture = false) => {
  document.applicationEventHandlers = document.applicationEventHandlers || {};

  removeAndroidEvent(event);

  document.applicationEventHandlers[event] = {
    handler,
    capture,
  };

  document.addEventListener(event, handler, capture);
};

export const saveModel = async (id, clazz, json) => {
  try {
    return await callToDevice('saveModel', id, clazz, JSON.stringify(json), new Date().getTime(), new Date().getTime());
  } catch (e) {
    throw e;
  }
};

export const getModel = async (id, clazz) => {
  callToDevice('getModel', id, clazz.name).then((model) => {
    let data = JSON.parse(model);
    let obj = new clazz(JSON.parse(data.json));
    return obj;
  }).catch((e) => {
    return e;
  });
};

export const payToPhone = async (amount, type = PaymentMethod.CARD) => {
  try {
    await callToDevice('payToPhone', amount * 100, type);
  } catch (e) {
    throw e;
  }
};

export const checkCameraPermission = async () => {
  try {
    await callToDevice('checkCameraPermission');
  } catch (e) {
    throw e;
  }
};
