import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RepositoryContext } from '../store/RepositoryProvider';
import { APICode } from '../models/types';

const RepositoryModel = ({ type, uid, render, onNotFound, onError }) => {
  const repository = useContext(RepositoryContext);

  const [model, setModel] = useState(null);
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const loadModel = useCallback(async () => {
    const model = await repository.getModel(type, uid, true);
    if (model) {
      setModel(model);
      setNotFound(false);
      setError(null);
    } else if (repository.load[type]) {
      repository.load[type]({ id: uid }).then((model) => {
        setModel(model);
        setNotFound(false);
        setError(null);
      }).catch((e) => {
        setModel(null);
        let errorCode = typeof e === 'object' ? e.error : e;
        if (errorCode === APICode.NOT_FOUND) {
          setNotFound(true);
          setError(null);
        } else {
          setError(e);
          setNotFound(false);
        }
      });
    } else {
      setModel(null);
      setNotFound(true);
      setError(null);
    }
  }, [uid]);

  useEffect(() => {
    loadModel();
  }, [uid]);

  return (
    <>
      {model && render(model)}
      {notFound && (typeof onNotFound === 'function' ? onNotFound() : onNotFound)}
      {error && (typeof onError === 'function' ? onError(error) : onError)}
    </>
  );
};


RepositoryModel.propTypes = {
  type: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  onNotFound: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]),
  onError: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
};

export default RepositoryModel;