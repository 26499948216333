import { Role } from './types';

class UserSession {
  constructor({ account, role, point, subdivision, logged_in = false } = {}) {
    this.account = account;
    this.role = role;
    this.point = point;
    this.subdivision = subdivision;
    this.logged_in = logged_in;
  }

  isOwner() {
    return this.role && this.role === Role.OWNER;
  }

  isAdmin() {
    return this.role && this.role === Role.ADMIN;
  }

  isManager() {
    return this.role && this.role === Role.MANAGER;
  }

  isAccountant() {
    return this.role && this.role === Role.ACCOUNTANT;
  }

  isGuide() {
    return this.role && this.role === Role.GUIDE;
  }

  isDriver() {
    return this.role && this.role === Role.DRIVER;
  }

  isCashier() {
    return this.role && this.role === Role.CASHIER;
  }

  isDispatcher() {
    return this.role && this.role === Role.DISPATCHER;
  }

  isCollector() {
    return this.role && this.role === Role.COLLECTOR;
  }
}

export default UserSession;