import { useTranslation } from 'react-i18next';
import NavBar from '../../components/NavBar';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Table, { ALIGN } from '../../components/Table';
import DateFormat from '../../components/DateFormat';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import DatePeriod from '../../components/DatePeriod';
import Modal from '../../components/Modal';
import SelectDate from '../cashbox/select_date';

const PLACE_READY = {
  [true]: BADGE_TYPE.SUCCESS,
  [false]: BADGE_TYPE.WARN
};

const ArchiveRoutes = () => {
  const {t} = useTranslation(['common', 'route']);

  const repository = useContext(RepositoryContext);

  const [schedules, setSchedules] = useState([]);
  const [collapse, setCollapse] = useState(-1);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showRangeModal, setShowRangeModal] = useState(false);

  const handleCloseRangeModal = useCallback(() => {
    setShowRangeModal(false);
  }, []);

  const handleApplyRangeModal = useCallback((range) => {
    setShowRangeModal(false);
    setStartDate(range.from);
    setEndDate(range.to || range.from);
  }, []);

  const toogleCollapse = useCallback((key) => {
    setCollapse(collapse === key ? -1 : key);
  }, [collapse]);

  const loadSchedules = async () => {
    let schedules = await repository.list(API.guid.schedules);

    let _schedules = schedules.filter((sch) => sch.places.length > 0);
    _schedules.forEach((sch) => {
      sch.passengers = sch.places.reduce((acm, { passengers: { actual, expected } }) => { return { actual: acm.actual + actual, expected: acm.expected + expected } }, { actual: 0, expected: 0 });
    });

    setSchedules(_schedules);
  };

  useEffect(() => {
    loadSchedules();
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, []);

  return (
    <div className="trip-app">
      <NavBar title={t('route:archive_routes.title')} />
      <div className="trip-app-body">
        <div className="header-line">{t('route:archive_routes.period')}</div>
        <div className="input">
          <DatePeriod captionLayout="dropdown" className="form-control" start={startDate} end={endDate} onClick={() => setShowRangeModal(true)}/>
        </div>

        <Table cols={5}>
          {schedules.map((s) => (
            <Fragment key={s.number}>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={2}>
                  {t('route:my_routes.route', { route: s.number })}
                </Table.Cell>
                <Table.Cell colspan={3} align={ALIGN.RIGHT}>
                  <DateFormat.DateTime date={s.date} />
                </Table.Cell>
              </Table.Row>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={5} className="small">
                  {s.excursions[0].title}
                </Table.Cell>
              </Table.Row>
              <Table.Row onClick={() => toogleCollapse(s.number)}>
                <Table.Cell colspan={3} className="small">
                  {s.transports.map((transport) => (
                    <div key={transport.id}>{transport.title}</div>
                  ))}
                </Table.Cell>
                <Table.Cell colspan={2} className="small" align={ALIGN.RIGHT}>
                  <Badge type={PLACE_READY[s.passengers.actual === s.passengers.expected]}>
                    {t('route:my_routes.seat', s.passengers)}
                  </Badge>
                </Table.Cell>
              </Table.Row>
              {s.places.map((place) => (
                <Table.Row className="sub-row" style={collapse === s.number ? {} : { display: 'none' }}>
                  <Table.Cell className="small"><DateFormat.Time date={place.time} /></Table.Cell>
                  <Table.Cell className="small" colspan={3}>{place.title}</Table.Cell>
                  <Table.Cell className="small" align={ALIGN.RIGHT}>
                    <Badge type={PLACE_READY[place.passengers.actual === place.passengers.expected]}>
                      {t('route:my_routes.count_place', place.passengers)}
                    </Badge>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row delim={true} />
            </Fragment>
          ))}
        </Table>

        <Modal show={showRangeModal} onHide={handleCloseRangeModal}>
          <Modal.Header closeButton>
            {t('cashbox:date_period.title')}
          </Modal.Header>
          <Modal.Body>
            <SelectDate start={startDate} end={endDate} onApply={handleApplyRangeModal} onCancel={handleCloseRangeModal}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default ArchiveRoutes;