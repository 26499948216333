import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Price from '../../components/Price';
import QRCode from '../../components/QRCode';
import { UserContext } from '../../store/UserProvider';
import Alert, { ALERT_TYPE } from '../../components/Alert';

const HandCash = ({ amountMax }) => {
  const {t} = useTranslation(['common']);

  const [cash, setCash] = useState(0);
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');

  const userInfo = useContext(UserContext);

  useEffect(() => {
    userInfo.getUser().then((u) => setQuery(u.id));
  }, []);

  const getCash = () => {
    let money = parseFloat(cash);
    return cash ? JSON.stringify({ query, cash: money, currency: 'rub' }) : '';
  };

  useEffect(() => {
    if (cash > 0 && cash > amountMax) {
      setError(t('cashbox:total_cash.over_limit'));
    } else {
      setError('');
    }
  }, [cash]);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {error && (
          <Alert type={ALERT_TYPE.ERROR} dismissible={true}>{error}</Alert>
        )}
        <div className="header-line">
          <span>{t('cashbox:total_cash.amount')}</span>
          <span className="link-action"><Price currency="rub" value={amountMax} onClick={() => setCash(amountMax)}/></span>
        </div>
        <Price.Input max={amountMax} min={0} value={cash} onChange={setCash}/>
        {cash >= 0 && !error && (
          <QRCode value={getCash()}/>
        )}
      </div>
    </div>
  );
}

HandCash.propTypes = {
  amountMax: PropTypes.number.isRequired
};

export default HandCash;